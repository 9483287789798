import { signal, effect, computed } from "@preact/signals-react";
import { useLocation } from 'react-router-dom';
const countSignal = signal<any[]>([]);
const scrolltoSignal = signal('');

const count2Signal = signal<any[]>([]);

const count3Signal = signal('/');

// console.log('signal called');


const dispose = effect(() => {

    //  console.log(window.location.pathname)
    //  console.log(count3Signal.value + ': route')
    // console.log(countSignal.value+ 'called')
 
} );

// dispose();
// Destroy effect and subscriptions:

  

export {countSignal, count2Signal, scrolltoSignal};