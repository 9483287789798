import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import {countSignal, count2Signal, scrolltoSignal} from './Signals/generalSignal'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './assets/css/custom.scss';

import './index.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

// import 'bootstrap/dist/css/bootstrap.css';



import App from './App';
import reportWebVitals from './reportWebVitals';

// import Home from './views/Home/Home';
import Error from './views/Error/Error';
import Header from './components/Header/Header';


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


const router = createBrowserRouter([


  {
    path: "/",
    element:
    (
      <>
      <Header  countSignal={countSignal} /> 
      <App countSignal={countSignal}  scrolltoSignal = {scrolltoSignal}  />
      </>
    )
  ,
  errorElement: <Error />,

  },
  {
    path: "*",
    element: <Error />,
    errorElement: <Error />,
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
 
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>


);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
