import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swipercomponent.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import img1 from '../../assets/img/img1.jpg'
import img2 from '../../assets/img/img2.jpg'
import img3 from '../../assets/img/img3.jpg'
import img4 from '../../assets/img/img4.jpg'

var swiperArray = [

  {
    id: '1',
    name: '1',
    image: img1,
    description: ''
  }
  ,
  {
    id: '2',
    name: '2',
    image: img2,
    description: ''
  },
  {
    id: '3',
    name: '3',
    image: img3,
    description: ''
  },
  {
    id: '4',
    name: '4',
    image: img4,
    description: ''
  }
 ]

const Swipercomponent = () => {


  
  return (
   
        
    <div className='styleSwiper'>

      <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]}
      spaceBetween={30}
      // slidesPerView={3}
      // navigation
      pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}

      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}

      onSwiper={(swiper: any) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >

    

      {




swiperArray.map((item: any) => {
        console.log(item)
 return (
  <SwiperSlide key={item?.id}>

  <div style={{ marginBottom: '0px' }} >

  <img  loading='lazy' src={item?.image} className="img"  alt={item.name} />

  </div>

</SwiperSlide>
 )
 

})

    
       }
      
    </Swiper>


    </div>

  )
}

export default Swipercomponent