
import {  Link, useRouteError } from "react-router-dom";
import './error.scss'

const Error = () => {
  
    const error: any = useRouteError();
    console.log(error);

   return (
    
     <>

     <div className='d-flex justify-content-center  flex-column'>

     <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>

      <Link className='align-self-center' to={'/'}>Go back to home</Link>
      
     </div>
   
    </>
  
   )
}

export default Error