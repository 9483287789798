import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import './header.scss'
import logo from '../../assets/img/doorbox.png'; 

 import {scrolltoSignal} from '../../Signals/generalSignal';
const Header = ({ countSignal }: any) => {

  const [theme , setTheme] = useState('')
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
  
   
    var theme = localStorage.getItem('theme');

    if(theme) {

     
      // setTheme(theme);

       if(theme === 'dark') {

        //  localStorage.setItem('theme', 'dark');
        //  setTheme('dark');
         document.getElementById("toggle-div")?.classList.add("night");
         document.getElementById("toggle")?.setAttribute('checked', 'true');
         document.body.setAttribute('data-bs-theme','dark')
         console.log('i got here')
        //  let element: any = document.getElementById('toggle-div');
        //  element.click();
       }
       if(theme === 'light') {

        //  localStorage.setItem('theme', 'light');
        //  setTheme('dark');
         document.getElementById("toggle-div")?.classList.remove("night");
         document.body.setAttribute('data-bs-theme','light')
      }
      
    }

    else {

       localStorage.setItem('theme', 'dark');
      //  setTheme('dark');
      // let element: any = document.getElementById('toggle-div');
      //  element.click();
       document.getElementById("toggle")?.setAttribute('checked', 'true');
       document.getElementById("toggle-div")?.classList.add("night");
       document.body.setAttribute('data-bs-theme','dark')
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("load", handleResize)

   }, []);



   
   const handleResize = () => {
        
    console.log(window.innerWidth)

    console.log("content loaded!");
   if (window.innerWidth < 720) {
       setIsMobile(true)
      
       console.log('yes')
   } else {
       setIsMobile(false)
       console.log('no')
   }
 }

   function slideTo(id: string) {
 
    scrolltoSignal.value = id;
   
   }



  useEffect(() => {
  
     document.getElementById("toggle")?.addEventListener("change", function (e: any) {
      e.preventDefault();
      if (e.target.checked) {

        document.getElementById("toggle-div")?.classList.add("night");
        document.body.setAttribute('data-bs-theme','dark')
        localStorage.setItem('theme', 'dark');
        // document.body.removeAttribute('data_bs_theme')
      
      } else {
      

        document.getElementById("toggle-div")?.classList.remove("night");
        document.body.setAttribute('data-bs-theme','light')
        localStorage.setItem('theme', 'light');
     
      }
    });

  }, [])

  useEffect(() => {
    console.log('i refreshed header')

     document.getElementById("toggle")?.addEventListener("change", function (e: any) {
      e.preventDefault();
      if (e.target.checked) {
        document.getElementById("toggle-div")?.classList.add("night");
      } else {
        document.getElementById("toggle-div")?.classList.remove("night");
      }
    });

  }, [countSignal?.value])
  

  return (
  


    <>


    <Navbar collapseOnSelect expand="lg" sticky="top" className="bg-body-tertiary" id='navbar'>
      <Container>
        <Navbar.Brand href="#home">


             <div className='imgbox'>
             <img
             loading='lazy'
              src={logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
             </div>


        </Navbar.Brand>


       
   
    <label htmlFor="toggle" id="toggle-label" className='darkmodecss'>
   <div id="toggle-div">
     <input type="checkbox"  id="toggle" />
     <div className="clouds">
       {/* <div className="cloud cloud-1"></div>
       <div className="cloud cloud-2"></div>
       <div className="cloud cloud-3"></div>
       <div className="cloud cloud-4"></div>
       <div className="cloud cloud-5"></div> */}
     </div>
     <div className="backdrops">
       <div className="backdrop"></div>
     </div>
     <div className="stars">
       <div className="star star-1"></div>
       <div className="star star-2"></div>
          <div className="star star-3"></div>
        </div>
        <div className="sun-moon">
          <div className="crater"></div>
        </div>
      </div>
    </label>

 

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">




 
          <Nav >


            <Nav.Link onClick={ () => slideTo('heroSection')} >Home</Nav.Link>
            <Nav.Link onClick={ () => slideTo('aboutsection')}>About</Nav.Link>
            <Nav.Link onClick={ () => slideTo('contactsection')}>Contact</Nav.Link>
            <Nav.Link onClick={ () => slideTo('servicesection')}>Services</Nav.Link>
            <Button variant="primary" size="sm" className='roundedbuttonsmall' href='https://doorbox.ng/admin/auth/login' target='_blank'>
              
             Login
           
           </Button>
            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>




  </>
  
   
  )
}

export default Header