import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Home from './views/Home/Home';

import { Route, Routes } from 'react-router-dom';


function App({countSignal, scrolltoSignal}: any) {
      
  return (
    <>  
     <Home  countSignal={countSignal} scrolltoSignal={scrolltoSignal}/>
    </>
  );
}

export default App;
